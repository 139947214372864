import Image from 'next/image';
import Link from 'next/link';
export default function Person(props) {

    return (
        <>
            <div className="flex flex-col basis-full sm:basis-1/2 flex-shrink-0 flex-grow md:flex-grow-0  md:basis-1/3 lg:basis-1/4  p-10">
                <div className="flex w-full mx-auto text-center justify-center">
                    <Link href={`/team/${props.slug}`}>
                        <a className="transition-all duration-300 hover:scale-110">
                            <Image
                                src={props.photo.data.attributes.url}
                                alt={props.photo.data.attributes.alternativeText}
                                width={props.photo.data.attributes.width}
                                height={props.photo.data.attributes.height}
                                className="rounded-md overflow-hidden"
                            />
                        </a>
                    </Link>
                </div>
                <div className="flex flex-col text-center w-full">
                    {props.fullName && (
                        <Link href={`/team/${props.slug}`}>
                            <a className="transition-all duration-300 hover:scale-110">
                                <h2 className="text-trust-500 font-semibold text-center text-lg mt-2">{props.fullName}</h2>
                            </a>
                        </Link>
                    )}
                    {props.title && <strong className="mb-4 font-semibold text-sm min-h-[2.5rem]">{props.title}</strong>}
                    {props.shortBio && (
                        <span className="mb-4 min-h-[12rem]" dangerouslySetInnerHTML={{ __html: props.shortBio }}></span>
                    )}
                    <Link href={`/team/${props.slug}`}>
                        <a className="transition-all duration-300 btn trust-border">
                            Read more
                        </a>
                    </Link>
                </div>
            </div >
        </>
    );
}
