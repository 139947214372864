import { animate, inView, scroll, ScrollOffset } from 'motion';
import Photo from './photo';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export default function HeroSection(props) {
 
 
    useEffect(()=> {
        
        if (props.animation === 'scale-on-scroll') {
            // const elem = document.getElementById(`hero-${props.id}`).nextElementSibling;
            // const observer = new IntersectionObserver(callback, { root: null, threshold: 1 });
            // observer.observe(elem);
            // return () => {
            //     observer.disconnect();
            // }

            scroll(
                animate(document.getElementById(`hero-${props.id}-image`), { scale: [1, 1.5], opacity: [1,-0.5] }), 
            {target: document.getElementById(`hero-${props.id}`),
                offset: ["100px start", "end start"]
            });
        }
        
    }, [props]);

    // const callback = (entries, observer) => {
    //     console.log(entries);
    //     setShouldStick(entries[0].isIntersecting)
    // }

    
    return (
        <section id={`hero-${props.id}`} >
            <div className={`hero-section relative  ${props.headerCss ? props.headerCss : ''}`}>
                <div className="relative w-full flex">
                    {props.image?.data && props.imageAsBackground && (
                        <>
                            <div
                                className={`hero-section-bg absolute z-1 top-0 w-full h-full bg-no-repeat bg-cover bg-opacity-20  bg-center `}
                                style={{
                                    backgroundImage: `url(${props.image.data.attributes.url})`
                                }}
                            ></div>
                            <div className="container flex relative z-2 ">
                                <div className="hero-section-content">
                                    <div
                                        className={` flex flex-col text-white justify-start`}
                                        dangerouslySetInnerHTML={{ __html: props.content }}
                                    ></div>
                                    {props.buttons.length > 0 && (
                                        <div className="mt-4">
                                            {props.buttons.map((btn) => (
                                                <Link key={btn.id.toString()} href={btn.link}>
                                                    <a target={btn.target} className={`btn btn-lg mr-2 ` + btn.style}>
                                                        {btn.label}
                                                    </a>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {/* if image but not as background and content horizontal */}
                    {props.image?.data && !props.imageAsBackground && props.Layout == "horizontal" && (
                        <div className="container">
                            <div className="flex items-center justify-start py-[10rem]">
                                <div id={`hero-${props.id}-image`} className="flex w-2/5 mx-auto md:w-1/2 relative z-20 pl-8 justify-center">
                                    <Photo image={props.image}></Photo>
                                </div>
                                <div
                                    className="flex flex-col w-full p-8 pt-0 md:w-2/3 text-white justify-start"
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                ></div>
                            </div>
                        </div>
                    )}

                    {/* if image but not as background and content vertical */}
                    {props.image?.data && !props.imageAsBackground && props.Layout == "vertical" && (
                      
                        <div className="flex flex-col items-center justify-center relative mx-auto mb-[20vh]">
                                <div
                                    className="flex flex-col w-full p-8 pt-0 md:w-2/3 text-white justify-center text-center"
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                ></div>
                            <div id={`hero-${props.id}-image`} className={`flex w-full mx-auto md:w-full m-0 px-4 xl:p-0 justify-center`}>
                                <div className="rounded-xl overflow-hidden p-0 m-0 flex">
                                    <Photo image={props.image} ></Photo>
                                </div>
                                </div>
                            </div>
                      
                    )}

                    {/* if no image */}
                    {!props.image?.data && (
                        <div className="container">
                            <div className="flex items-center justify-start pt-[6rem] pb-[6rem]">
                                <div
                                    className={`flex flex-col w-full p-10 ${!props.full ? 'md:w-2/3' : ''
                                        } text-white justify-start`}
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                ></div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </section>
    );
}
