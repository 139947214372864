'use client';
import Link from 'next/link';
import { fetchAPI } from '../lib/api';
import React, { useEffect, useState } from 'react';
import Photo from './photo';
const JobList =  (props) => {
    // const results = await fetchAPI('/jobs', { locale: 'ro', populate: 'deep,1' });
    // console.log(results);
    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [cols,setCols] = useState(0);

    useEffect(() => {
        fetchAPI('/jobs', { locale: 'ro', fields: ['title', 'slug', 'short_description'], populate: 'deep,3', }).then((results) => {;
            setJobs(results.data);
            setCols(results.data.length < 2 ? 1 : results.data.length < 5 ? 2 : 3)   
            setIsLoading(false);
        });
    }, [setIsLoading, setJobs, setCols]);

    return (
        <section className="max-w-screen-xl w-full mx-auto"> 
            {props.content && 
                <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
            }


            {isLoading && (
                <div>Loading...</div>
            )}
            {!isLoading && (
                <div className={`jobs gap-20 p-10 xl:p-0 pt-0 -mt-20 mb-20 z-10 relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${cols} ${cols > 1 ? 'md:job-spacing' : '' }`}>
                {jobs.map((job) => (
                    <div key={job.id} className="image-box">
                        <div className="image-box-wrapper">
                            <div className="image flex w-full">
                                <Photo image={job.attributes.image} objectFit={'cover'} />
                            </div>
                            <div className="p-10">
                                <h2 className="text-2xl">{job.attributes.title}</h2>
                                <div dangerouslySetInnerHTML={{__html: job.attributes.short_description}}></div>
                                <Link href={`/jobs/${job.attributes.slug}`}>
                                    <a title={`More information on ${job.attributes.title}`} className="btn action-border rounded-xl">Mai multe informatii</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            )}
        </section>
    );

}

export default JobList;