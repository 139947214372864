import { useGlobalContext } from '../lib/global-context';
import Link from 'next/link';
export default function ContactBlock(props) {
    const { state } = useGlobalContext();
    const global = state;

    return (
        <section>
            <div className="container">
                <div className="flex flex-col md:flex-row text-center justify-center items-start flex-wrap pt-10">
                    {global.company.contactInfo.map((c, index) => {
                        return (
                            <div
                                key={`contact-block-${c.title}-${index}+`}
                                className="flex flex-col justify-center w-full md:basis-1/3 px-8 py-10"
                            >
                                {c.icon && (
                                    <div className="rounded-[0.25rem] bg-gray-50 text-maturity-500 text-center p-4 mx-auto mb-4">
                                        <i className={`flaticon-${c.icon.icon} text-6xl`}></i>
                                    </div>
                                )}
                                {c.title && <strong className="!text-md text-maturity-900">{c.title}</strong>}
                                {c.description && <p>{c.description}</p>}
                                {c.phone && (
                                    <Link href={`tel:${c.phone}`}>
                                        <a className="text-trust pt-2">{c.phone}</a>
                                    </Link>
                                )}
                                {c.email && (
                                    <Link href={`mailto:${c.email}`}>
                                        <a className="text-trust pt-2">{c.email}</a>
                                    </Link>
                                )}
                            </div>
                        );
                    })}

                    <div className="flex flex-col justify-center w-full md:basis-1/3 px-8 py-10">
                        <div className="rounded-[0.25rem] bg-gray-50 text-maturity-500 text-center p-4 mx-auto mb-4">
                            <i className={`flaticon-location text-6xl`}></i>
                        </div>

                        <strong className="!text-md text-maturity-900">Address</strong>
                        <span>{global.company.name}</span>
                        <span dangerouslySetInnerHTML={{ __html: global.company.address }}></span>
                        {global.company.workingHours && <span className="mt-4">
                            Working Hours: <br />
                            {global.company.workingHours}
                        </span>}
                    </div>
                </div>
            </div>
        </section>
    );
}
