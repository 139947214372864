import Section from './section';
import Photo from './photo';
import Link from 'next/link';
export default function FeaturedEvent(props) {
    const events = props.events.data.filter((e) => e.attributes.isFeatured);
    const event = events[0];

    return (
        <>
            {event && (
                <Section className="mt-10 z-10 relative px-6">
                    <div className="rounded-md shadow-lg border-[1px] border-maturity bg-white overflow-hidden">
                        <div className="h-30 relative text-center flex items-center justify-center">
                            <Photo image={event.attributes.coverPhoto}></Photo>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex p-6 flex-col w-full md:w-2/3 ">
                                <h3 className="text-trust !text-xl block normal-case">
                                    {event.attributes.title}
                                </h3>
                                <span
                                    className="block my-4"
                                    dangerouslySetInnerHTML={{ __html: event.attributes.shortDescription }}
                                ></span>
                            </div>
                            <div className="flex w-full md:w-1/3 flex-shrink-0 p-6 items-center justify-start md:justify-end">
                                <Link href={`/events/${event.attributes.slug}`}>
                                    <a className="btn btn-md action-full">Read more</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Section>
            )}
        </>
    );
}
