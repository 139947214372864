import Person from './person';
export default function Team({ people }) {

    return (
        <div className="container relative">
            <div className="flex flex-wrap items-start justify-center p-4">
                {people.map((p) => (
                    <Person key={p.id} {...p.attributes}></Person>
                ))}
            </div>
        </div>
    );
}
