import SectionTitle from './section-title';
import Photo from './photo';
import { InfiniteMovingCards } from './infinite-moving-cards';

export default function ClientList({ settings }) {


    return (
        <>
            {settings.style == "list" && (
                <div className='container mx-auto '>
                    <InfiniteMovingCards items={settings.clients.data} direction="left" speed="slow" className="bg-gray-100" />
                </div>
            )}
            {settings.style != "list" && (
                <section className='client-list'>
                    <div className={`container  ${settings.style}`}>
                        <div className={`flex flex-col ${settings.style === 'text-top' ? '' : 'xl:flex-row'}`}>
                            <div className={`flex w-full ${settings.style === 'text-top' ? '' : 'xl:w-2/5'}`}>
                                <SectionTitle
                                    title={settings.title}
                                    description={settings.description}
                                    style="text-trust"
                                    className="w-full"
                                ></SectionTitle>
                            </div>
                            <div className={`flex w-full ${settings.style === 'text-top' ? '' : 'xl:w-3/5'}`}>
                                <div className="w-full flex flex-wrap p-10 lg:py-0 mx-auto">
                                    {settings.clients.data.map((entry) => (
                                        <div
                                            key={entry.id.toString()}
                                            className={`pt-0 px-2 flex w-1/2 md:w-1/3 lg:w-1/4  ${settings.style === 'text-top' ? 'xl:w-1/5' : ''} h-32 items-center justify-center lg:flex-col opacity-30 hover:opacity-80 scale-100 hover:scale-125 transition duration-500 ease-in-out`}
                                        >
                                            <Photo image={entry.attributes.logo}></Photo>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
