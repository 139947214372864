import Section from './section';
import Photo from './photo';
import Link from 'next/link';
import Image from 'next/image';
import { useGlobalContext } from '../lib/global-context';
export default function NewsList(props) {
    const { state } = useGlobalContext();
    const global = state;


    return (
        <Section className="mt-10 z-10 relative px-6">
            {props.title && (
                <span
                    className="text-trust ml-2 block relative z-20"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                ></span>
            )}

            <div className="grid grid-cols-1 px-2 my-10 md:grid-cols-2 lg:grid-cols-3 gap-10">
                {props.news.data.map((e, index) => (
                    <div
                        key={e.id.toString()}
                        className="rounded-md shadow-lg border-[1px] border-maturity bg-white overflow-hidden"
                    >
                        <Link href={`/news/${e.attributes.slug}`}>
                            <a className="aspect-video relative text-center flex items-center justify-center overflow-hidden">
                                <Image
                                    src={e.attributes.coverPhoto.data.attributes.formats.small ? e.attributes.coverPhoto.data.attributes.formats.small.url : e.attributes.coverPhoto.data.attributes.url}
                                    objectFit={'cover'}
                                    alt={e.attributes.coverPhoto.data.attributes.alternativeText}
                                    layout="fill"
                                    priority={index}
                                    quality={85}
                                    className="cursor-pointer"
                                ></Image>
                            </a>
                        </Link>
                        <div className="p-6">
                            <div className="bg-maturity-100 px-2 py-1 text-xs rounded-md inline-block">
                                {new Date(e.attributes.date).toDateString(global.locale)}
                            </div>
                            <Link href={`/news/${e.attributes.slug}`}>
                                <a className="text-trust hover:text-trust-500 font-bold mt-4 !text-lg block normal-case cursor-pointer">
                                    {e.attributes.title}
                                </a>
                            </Link>

                            <span
                                className="block my-4"
                                dangerouslySetInnerHTML={{ __html: e.attributes.shortDescription }}
                            ></span>
                        </div>
                    </div>
                ))}
            </div>
        </Section>
    );
}
