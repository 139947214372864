import Link from 'next/link';

export default function HeroVideo(props) {

    return (
        <div className={`hero hero-video ${props.className ? props.className : ''}`}>


            <div className='content-wrapper px-4'>
                <span className='hero-video-content' dangerouslySetInnerHTML={{ __html: props.content }}></span>
                {props.buttons.length > 0 && (
                    <div className="button-list">
                        {props.buttons.map((btn) => (
                            <Link key={btn.id.toString()} href={btn.link}>
                                <a target={btn.target} className={`btn btn-lg mr-2 ` + btn.style}>
                                    {btn.label}
                                </a>
                            </Link>
                        ))}
                    </div>
                )}
            </div>

            {/* <div className="overlay"></div> */}
            <video defaultmuted="true" playsInline muted={true} autoPlay={true} poster={props.poster.data.attributes.url} loop={true}>
                <source src={props.video.data.attributes.url} type="video/mp4" />
            </video>
            {props.className?.includes('angled') && (
                <div className="skew-bottom"></div>
            )}
        </div>

    );
}
