import Section from './section';
import Photo from './photo';
import { useGlobalContext } from '../lib/global-context';
import Link from 'next/link';
export default function EventList(props) {
    const events = props.events.data.filter((e) => !e.attributes.isFeatured);
    const { state } = useGlobalContext();
    const global = state;

    return (
        <Section className="mt-10 z-10 relative px-6">
            {props.title && (
                <span
                    className="text-trust ml-2 block relative z-20"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                ></span>
            )}

            <div className="grid grid-cols-1 px-2 mt-10 md:grid-cols-2 lg:grid-cols-3 gap-10">
                {events.map((e) => (
                    <div
                        key={e.id.toString()}
                        className="rounded-md shadow-lg border-[1px] border-maturity bg-white overflow-hidden"
                    >
                        <div className="aspect-video relative text-center flex items-center justify-center">
                            <Photo layout="fill" image={e.attributes.coverPhoto}></Photo>
                        </div>
                        <div className="p-6">
                            <h3 className="text-trust !text-xl block normal-case">{e.attributes.title}</h3>
                            <div className="bg-maturity-100 px-2 py-1 text-xs rounded-md inline-block">
                                {new Date(e.attributes.date).toDateString(global.locale)}
                            </div>
                            <span
                                className="block my-4"
                                dangerouslySetInnerHTML={{ __html: e.attributes.shortDescription }}
                            ></span>
                            <Link href={`/events/${e.attributes.slug}`}>
                                <a className="btn action-border">Read more</a>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </Section>
    );
}
